.scrollToTop{
    background-color: #00C2CB !important;
    position: relative;
    z-index: 10 !important;
    i{
        color: #fff;
        font-weight: bold;
    }
    transition: 250ms;
    &:hover{
        transform: translateY(-8px);
    }
}