.mySwiper{

.swiper-pagination .swiper-pagination-bullet {
  background: #C1C3C6 !important;
  opacity: 1 !important;
  margin: 0 10px !important;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background: #00C2CB !important;
  position: relative;
}
.swiper-pagination .swiper-pagination-bullet-active:before {
  position: absolute;
  content: "";
  width: 26px;
  height: 26px;
  background: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  border: 1px solid #01999C;
} 
}