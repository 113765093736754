
@import "https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap";
@import "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&amp;display=swap";
@import "https://fonts.googleapis.com/css2?family=Pacifico&amp;display=swap";
@import "react-image-gallery/styles/css/image-gallery.css" ;
// @import "~react-image-gallery/styles/scss/image-gallery.scss" ;


// first color #00C2CB second color #01999C
body{
    font-family: "DM Sans",sans-serif ;
}
::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #00C2CB;
    border-radius: 50px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #01999C;
  }

  .header-account{
    .register{
      select{
        @media (max-width:998px){
          display: none;
        }
      }
    }
    p{
     @media (max-width:998px){
      display: none;
     }
    }
  }

@import "./others/ScrollToTop/scroll"       ;
@import "./others/Pagination/pagination"    ;
@import "./others/Swiper/swiper.scss"       ;
@import "./home/mobileMenu"                 ; 
@import "_footer.scss"                      ;


.image-gallery-image{
  max-height: calc(100vh - 80px);
  position: relative;
}
.image-gallery-bullets .image-gallery-bullets-container{
  position: absolute;
  display: flex;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  text-align: center;
}

.listing-list-car-grid.listing-list-car-list .listing-grid-item .listing-item-content{
  // background-color: red;
  padding: 20px 15px;
}
.listing-grid-item .listing-item-content .description ul {
  padding-left: 0;
}

.container{
  .row{
    .imgContainer{
      position: relative;
      img{
        position: absolute;
        bottom: 0;
      }
      .d-flex{
        position: absolute;
        bottom: 0;
        gap: 50px;
      }
      .none{
        display: none;
      }
      .active{
        display: block;
      }
    }
  }
}

input[type="range"] {
  -webkit-appearance: none; 
  -moz-apperance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background: #F6F6F6; 
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; 
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #00C2CB; 
  cursor: pointer;
  transition: 250ms;
}
input[type="range"]:hover::-webkit-slider-thumb {
  background-color: #00C2CB;
  box-shadow: 0 0 15px rgba(11, 155, 203, 0.83); 
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none; 
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
}

.image-gallery{
  width: 100%;
  .image-gallery-fullscreen-button{
    width: auto;
    display: flex;
    justify-content: space-between;
    color: #fff;
    }
}
.image-gallery-image{
  width: 100% !important;
  object-fit: cover !important;
}

.dropnavcars{
  .row{
    .active{
      color: #00C2CB;
    }
    a{
      color: #000;
      text-decoration: none;
      transition: 250ms;
      &:hover{
        color: #01999C;
      }
    }
  }
}


.nav-link.active{
  background-color: #01999C !important;
}

.nav-link{
  &:hover{
    background-color: #01999C !important;
  }
  
}

.invalid_message{
  color: red;
}
.modalBtn{
  background-color: #00C2CB;
  color: #fff !important;
  font-size: 18px;
  font-weight: bold;
  &:hover{
    background-color: #01999C;
    color: #fff !important;
  }
  &:focus{
    background-color: #00C2CB;
    box-shadow: none;
    border: none;
    color: #fff !important;
  }
  
}

.monthBtn{
  background-color: transparent;
  border: 1px solid #00C2CB;
  color: #000;
  height: 0;
  width: auto  !important;
  border-radius: 100px;
}
.activeMonth{
  background-color: #01999C;
  color: #fff;
  border: 1px solid #00C2CB;
}

.modalRange{
  background-color : #21d5d8 !important;
}

.btnBuyNow{
  background-color: #00C2CB !important;
  &:hover{
    background-color: #01999C !important;
  }
}

div:where(.swal2-icon).swal2-success{
  border-color: #00C2CB !important;
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-line]{
  background-color: #00C2CB !important;
}

.modal-backdrop.show{
  display: none ;
}

.modalCol{
 @media (max-width:548px){
  padding: 0 !important;
 }
}

// .images{
//   transition: 250ms !important;
// }


.hover-listing-image{
  @media (max-width:463px){
    min-height: 290px;
  }
  @media (max-width:450px){
    min-height: 270px;
  }
  @media (max-width:420px){
    min-height: 250px;
  }
  @media (max-width:390px){
    min-height: 240px;
  }
  @media (max-width:370px){
    min-height: 220px;
  }
  @media (max-width:343px){
    min-height: 180px;
    height: 100%;
  }
}

.listing-item{
  .images{
    transition: 250ms !important;
  }
}

.rangeslider {
  width: 100%;
  height: 8px;
  background-color: #f6f6f6;
  border-radius: 10px;
  position: relative; 
}

.rangeslider .thumb {
  width: 17px;
  height: 17px;
  background-color: #00c2cb;
  border-radius: 50%;
  cursor: grab;
  position: absolute; 
  top: -5px;
  transition: box-shadow 0.2s ease; 
}

.rangeslider .thumb:focus {
  outline: none; 
  box-shadow: 0 0 0 3px rgba(0, 194, 203, 0.4); 
}
