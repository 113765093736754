


.menuBtn {
  display: none;
  width: auto;
  background-color: transparent;
  i{
    color: #00C2CB;
    font-size: 20px;
  }
  @media (max-width:998px){
    display: block;
    
  }
}

.offcanvas-header {
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.08) !important; 
 }

 .offcanvas-body{
  a{
    color: #000;
    text-decoration: none;
  }
  .active{
    color: #00C2CB;
  }
  .dropdown{
    display: none !important;
    background-color: #f8f8f8;
    padding: 15px 20px;
    transition: 250ms;
    
  }
  
  .active{
    display: block !important;
    border-radius: 10px;
    transition: 250ms;
  }
  p{
    font-weight: 500;
  }
  ul{
    padding-left: 0;
    li{
      a{
        text-decoration: none;
        font-weight: 400;
        color: #000;
        transition: 250ms;
        margin-bottom: 10px;
            &:hover{
                color: #00C2CB;
            }
           }
        }
    }
 }

 .mobileSelect{
   display: none;
   @media (max-width:998px){
    display: flex;
   }
   select{
    border: none;
    color: #00C2CB;
    font-weight: bold;
    option{
      color: #000;
      font-weight: 500;
    }
  }
 }