.pagination_main{

    .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        
    }
  
    .active{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 49px;
        height: 49px;
        border: 1px solid #f5f1ea;
        background-color: #00C2CB;
        border-radius: 5px;
        color: #fff !important;
        font-weight: bold;
    }
    .inActive {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 49px;
        height: 49px;
        border: 1px solid #f5f1ea;
        border-radius: 5px;
        color: #000;
        font-weight: bold;
        transition: 250ms;
        a{
            padding: 10px 20px;
        }
        &:hover{
            background-color: #01999C;
            color: #fff;
            cursor: pointer;
        }
    }
    .break{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 49px;
        height: 49px;
        border: 1px solid #f5f1ea;
        border-radius: 5px;
        color: #000;
        font-weight: bold;
        transition: 250ms;
        &:hover{
            background-color:#01999C;
            color: #fff;
        }
    }
    .fa-angles-left{
        padding: 16.5px;
        border: 1px solid #f5f1ea;
        border-radius: 5px;
        color: #000;
        font-weight: bold;
        transition: 250ms;
        &:hover{
            background-color:#01999C;
            color: #fff;
        }
    }
    .fa-angles-right{
        padding: 16.5px;
        border: 1px solid #f5f1ea;
        border-radius: 5px;
        color: #000;
        font-weight: bold;
        transition: 250ms;
        &:hover{
            background-color:#01999C;
            color: #fff;
        }
    }
}